import axios from 'axios'
import { CMOTS_AUTH_PASSWORD, CMOTS_AUTH_USERNAME } from '../helpers/constants'
import { CMS_API_URL as CMS_URL } from '../vars/url'
import { getUser } from './storageServices'

let host = window.location.hostname
let path = '';
let token = '';
if (host === 'localhost') {
    // path = "https://broking-uat-westindia-apigateway.azure-api.net/";
    // token = "e6c610d5100f4b37b94381f61045a5f2";
    // path = "https://broking-apigateway.indiainfoline.com/";
    // token = "80821536e75444f9abcb09a4ec9558aa";
    path = process.env.REACT_APP_BROKING_API_LOCAL;
    token = process.env.REACT_APP_BROKING_TOKEN_LOCAL;
}
// else if(host === 'oneupuat')
// {
//     path = "https://broking-uat-westindia-apigateway.azure-api.net/";
//     token = "e6c610d5100f4b37b94381f61045a5f2";
// }
else {
    // path = "https://broking-apigateway.indiainfoline.com/";
    // token = "80821536e75444f9abcb09a4ec9558aa";
    // path = "https://broking-uat-westindia-apigateway.azure-api.net/";
    // token = "e6c610d5100f4b37b94381f61045a5f2";
    path = process.env.REACT_APP_BROKING_API_PROD;
    token = process.env.REACT_APP_BROKING_TOKEN_PROD;
}


export const API_PATH = path;
export const OCM_TOKEN = token;
export const cmsServices = axios.create({
    baseURL: CMS_URL
})

export const apiServices = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
    }

})
export const postapiservices = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
    }
})

export const npsPostapiservices = axios.create({
    baseURL: process.env.REACT_APP_BROKING_API_NEW,
    headers: {
        "Ocp-Apim-Subscription-Key": token
    },
});

export const razorpayPostapiservices = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export const appliediposervice = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
        'Authorization': 'IMN05OPLoDvbTTaIQkqLNMI7cPLguaRyHzyg7n5qNBVjQmtBhzF4SzYh4NBVCXi3KJHlSXKP+oi2+bXr6CUYTRL'
    }
})

export const appliedApiServices = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
    }

})

export const getapiservices = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
    }

})

export const otpApiServices = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
        'Authorization': 'IMN05OPLoDvbTTaIQkqLNMI7cPLguaRyHzyg7n5qNBVjQmtBhzF4SzYh4NBVCXi3KJHlSXKP+oi2+bXr6CUYTRL'
    }
})

export const loginApIServices = axios.create({
    baseURL: "https://mobilegateway.iifl.in/mymoney",
    headers: {
        'Content-type': 'Application/json',
        'Ocp-Apim-Subscription-Key': '64a0d4d54ae14d5d875839fdcaa3ff1b',
    }
})

const tokenConfig = (config) => {
    const user = getUser()
    if (user && user?.token) {
        config.headers['Authorization'] = 'Bearer ' + user?.token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
}

const appliedConfig = (config) => {
    const user = getUser()
    if (user && user?.token) {
        config.headers['Authorization'] = 'Bearer ' + user?.token;
        config.headers['RequesterCode'] = user?.loginid;
        config.headers['ClientCode'] = user?.clientcode;
    }

    config.headers['Content-Type'] = 'application/json';
    return config;
}

apiServices.interceptors.request.use(tokenConfig, (e) => {
    Promise.reject(e)
})

postapiservices.interceptors.request.use(tokenConfig, (e) => {
    Promise.reject(e)
})

npsPostapiservices.interceptors.request.use(appliedConfig, (e) => {
    Promise.reject(e);
});

appliedApiServices.interceptors.request.use(appliedConfig, (e) => {
    Promise.reject(e)
})

export const cmotsAPIServices = axios.create({
    baseURL: "https://content.dionglobal.in/",
})

export const trendlyneapi = axios.create({
    baseURL: "https://newsiifl.trendlyne.com",
    headers: {
        "userId": "shF24178l4Ksdj74Android219fff7sjktWue2qr4rrhdkd",
        "password": "thbmdsggjsd7e886ds5821kdhxgkrkbmqdudeklsse6d",
        "key": "eab16d83a9d0f05f9dd9d003a51a816781a67632",
        "requestCode": "IIFL"
    }
})

export const zohoAPIServices = axios.create({
    baseURL: "https://broking-apigateway.indiainfoline.com/ipo/",
    headers: {
        "Ocp-Apim-Subscription-Key": "80821536e75444f9abcb09a4ec9558aa",
    }
})

// ipo funding api start
export const ipoFundingApiService = axios.create({
    baseURL: path,
    headers: {
        "Ocp-Apim-Subscription-Key": token,
    }
})

ipoFundingApiService.interceptors.request.use(tokenConfig, (e) => {
    Promise.reject(e)
})
// ipo funding api End

export const cmsWrapService = axios.create({
    baseURL: CMS_URL
})